import { gql, useQuery } from '@apollo/client';
import { Box, Container, Counter, Flex, Icon, Image } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { Heart, ThumbsUp, Users } from '@energiebespaarders/symbols/icons/solid';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import React from 'react';
import Slick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import { GET_KLANTENVERTELLEN_REVIEWS } from '~/components/KlantenVertellenWidget';
import theme from '~/styles/theme';
import { amountBespaarchecks } from '~/types/generated/amountBespaarchecks';
import { getKlantenVertellenScore } from '~/types/generated/getKlantenVertellenScore';
import { PartnerKey } from '~/utils/constants';
import { DEB_PARTNERS } from '~/utils/debPartners';
import delimit from '~/utils/delimit';
import { useIsInitializing } from '~/hooks/useIsInitializing';

const StyledSliderLogo = styled(Image)`
  max-height: 1.5em;
  display: inline-block !important;
  vertical-align: baseline;
  object-fit: contain;

  ${mediaMin.md} {
    max-height: 2em;
  }
`;

export const BESPAARCHECKS = gql`
  query amountBespaarchecks {
    amountBespaarchecks
  }
`;

interface TrusterProps {
  singlePartner?: PartnerKey;
}

const Truster: React.FC<TrusterProps> = ({ singlePartner }) => {
  const mobile = useIsMobile();
  const { isInitializing } = useIsInitializing();
  const { data, error } = useQuery<amountBespaarchecks>(BESPAARCHECKS, {
    fetchPolicy: 'cache-first',
    skip: isInitializing,
  });
  const { data: reviewData } = useQuery<getKlantenVertellenScore>(GET_KLANTENVERTELLEN_REVIEWS, {
    fetchPolicy: 'cache-first',
    skip: isInitializing,
  });

  const reviewScore = reviewData?.getKlantenVertellenScore?.last12MonthAverageRating ?? 8.5;

  const sliderSettings = {
    accessibility: false,
    adaptiveHeight: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2500,
    centerMode: false,
    dots: false,
    draggable: false,
    fade: false,
    infinite: true,
    pauseOnHover: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const singlePartnerData = singlePartner
    ? DEB_PARTNERS.find(partner => singlePartner === partner.key)
    : undefined;

  return (
    <Flex style={{ background: theme.colors.greenSlate }}>
      <Container py={2} textAlign={mobile ? 'center' : 'left'}>
        <Flex flexWrap="wrap" alignItems="center" justifyContent="center" mx={[0, 0, 0, 0, -2]}>
          <Box width={[1, 1, 1 / 3]} px={2} pt={1}>
            <p>
              <Icon icon={Users} solid fill="green" mr={1} />{' '}
              {error ? (
                '70.000+'
              ) : (
                <Counter
                  value={data?.amountBespaarchecks ?? 70000}
                  duration={1000}
                  formatValue={(n: number) => delimit(Math.round(n), 0)}
                  className="bold"
                />
              )}{' '}
              mensen gingen je voor
            </p>
          </Box>
          <Box width={[1, 1, 1 / 3]} px={1} pt={1}>
            <p>
              <Icon icon={ThumbsUp} solid fill="green" mr={1} />
              Onze klanten geven ons gemiddeld een{' '}
              <Medium>
                <Counter
                  value={reviewScore}
                  duration={1000}
                  formatValue={(n: number) => delimit(n, 1)}
                />
              </Medium>
            </p>
          </Box>
          <Box width={[1, 1, 1 / 3]} px={1} pt={1} textAlign={mobile ? 'center' : 'right'}>
            <Flex flexWrap="wrap">
              <Box width={[1, 1, 2 / 3]} pr={1}>
                <p>
                  <Icon icon={Heart} solid fill="green" mr={1} />
                  Onafhankelijk partner van{' '}
                </p>
              </Box>
              <Box width={[1, 1, 1 / 3]} style={{ position: 'relative' }}>
                {singlePartnerData ? (
                  <StyledSliderLogo
                    src={singlePartnerData.logo}
                    alt={singlePartnerData.name}
                    className="img-responsive"
                    style={{
                      display: 'inline',
                      width: singlePartnerData.logoWidth || 'auto',
                      maxHeight: singlePartnerData.logoHeight || 'auto',
                    }}
                  />
                ) : (
                  <Slick {...sliderSettings}>
                    {DEB_PARTNERS.filter(partner => !partner.hide).map((partner, index) => (
                      <StyledSliderLogo
                        key={`${partner}-${index}`}
                        src={partner.logo}
                        alt={partner.name}
                        className="img-responsive"
                        loading="lazy"
                      />
                    ))}
                  </Slick>
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Truster;
